section {
  padding: 40px 20px;
  background-color: #f0f0f0;
  text-align: center;
}

.contact h2 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.contact form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px;
  margin: 0 auto;
}

.contact label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.contact input[type="text"],
.contact input[type="email"],
.contact input[type="tel"],
.contact textarea {
  width: 100%;
  padding: 10px;
  border: 2px solid #007bff;
  border-radius: 10px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.contact input[type="text"]:focus,
.contact input[type="email"]:focus,
.contact input[type="tel"]:focus,
.contact textarea:focus {
  border-color: #0056b3;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  outline: none;
}

.contact textarea {
  height: 100px;
  resize: none;
}

.contact .message-label {
  position: relative;
}

.contact .info-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.contact .info-icon {
  margin-right: 5px;
}

.contact-preference {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.contact-preference-title {
  margin-right: 20px;
  white-space: nowrap;
}

.radio-option {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  margin-right: 20px;
  margin-top: 16px;
}

.radio-option input[type="radio"] {
  margin-right: 5px;
}

.contact button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;
  align-self: center;
}

.contact button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modal-content h3 {
  margin: 0;
  font-size: 24px;
  color: #007bff;
}

.modal-content p {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
}

.modal-close {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.modal-close:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .contact h2 {
    font-size: 28px;
  }

  .contact label {
    font-size: 16px;
  }

  .contact input[type="text"],
  .contact input[type="email"],
  .contact input[type="tel"],
  .contact textarea {
    font-size: 14px;
    width: 90%;
  }

  .contact-preference {
    flex-direction: column;
    align-items: flex-start;
  }

  .radio-option {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
