#our-work {
  padding: 50px;
  background-color: #4a4a4a;
  border-radius: 3rem;
}

.our-work-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.image-container-our-work {
  flex: 1;
  text-align: center;
  margin: 20px; /* Espaciado alrededor de las imágenes */
}

.our-work-image1 {
  height: auto;
  max-width: 250px; /* Ajusta el tamaño de las imágenes */
  margin: 0 auto;
  margin-top: 400px;
}

.our-work-image2 {
  height: auto;
  max-width: 300px; /* Ajusta el tamaño de las imágenes */
  margin: 0 auto;
  margin-top: 100px;
}

.content-our-work {
  flex: 2;
  max-width: 800px;
  margin: 20px; /* Espaciado alrededor del contenido */
}

.content-our-work h2 {
  font-size: 32px;
  font-weight: bold;
  color: #f5f5dc;
  margin-bottom: 20px;
}

.content-our-work p {
  font-size: 18px;
  line-height: 1.6;
  color: #f5f5dc;
  margin-bottom: 20px;
}

.content-our-work ul {
  margin-bottom: 20px;
  padding-left: 20px;
}

.content-our-work li {
  font-size: 18px;
  line-height: 1.6;
  color: #f5f5dc;
  margin-bottom: 10px;
}

.trusted-companies {
  margin-top: 40px;
}

.trusted-companies h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.companies-logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.company-logo {
  height: auto;
  width: 500px; /* Ajusta el tamaño de los logos de las empresas */
  margin: 10px; /* Espaciado entre los logos */
}

/* Media query para pantallas pequeñas */
@media (max-width: 767px) {
  .our-work-container {
    flex-direction: column;
    align-items: center;
  }

  .image-container-our-work {
    margin: 10px 0; /* Reducir margen en pantallas pequeñas */
  }

  .our-work-image1, .our-work-image2 {
    max-width: 100%;
    margin-top: 20px; /* Reducir margen superior en pantallas pequeñas */
  }

  .content-our-work {
    max-width: 100%;
    padding: 20px; /* Reducir padding en pantallas pequeñas */
    text-align: center; /* Centrar texto en pantallas pequeñas */
    margin: 0px;
    padding: 0px;
  }

  .content-our-work h2 {
    font-size: 24px; /* Reducir tamaño de fuente en pantallas pequeñas */
  }

  .content-our-work p, .content-our-work li {
    font-size: 16px; /* Reducir tamaño de fuente en pantallas pequeñas */
  }

  .trusted-companies h3 {
    font-size: 20px; /* Reducir tamaño de fuente en pantallas pequeñas */
  }

  .company-logo {
    width: 100%; /* Ajustar tamaño de logos en pantallas pequeñas */
    margin: 10px 0; /* Reducir margen entre logos en pantallas pequeñas */
  }
}
