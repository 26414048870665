.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  position: relative;
}

.App-logo {
  height: 150%;
}

.App-nav {
  display: flex;
  align-items: center;
}

.App-nav a,
.dropbtn {
  color: white;
  text-decoration: none;
  padding: 14px 20px;
  font-size: 18px;
  display: inline-block;
  transition: background-color 0.3s;
  background: none;
  border: none;
}

.App-nav a:hover,
.dropdown:hover .dropbtn {
  background-color: #575757;
}

/* --------------- Dropdown --------------- */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn::after {
  content: " ▼";
  font-size: 12px;
  margin-left: 5px;
}

.clickable {
  cursor: pointer;
}

/* Hamburger menu button */
.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 20px;
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

.contact-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .App-nav {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #282c34;
    padding: 20px 0;
  }

  .App-nav.show {
    display: flex;
  }

  .App-nav a,
  .dropbtn {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    text-align: center;
  }

  .dropdown-content {
    position: static;
    width: 100%;
    box-shadow: none;
  }

  .dropdown-content a {
    padding: 10px;
    font-size: 16px;
  }

  .dropdown-content a:hover {
    background-color: #575757;
    color: white;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    position: relative;
  }

  .hamburger-menu {
    display: flex;
  }

  .App-logo {
    margin-right: auto;
    height: 150%;
  }

  .contact-button {
    margin-left: 20px;
  }
}
