#technology-advice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: #f8f8f8;
}

.image-container {
  flex: 1;
  padding-right: 50px;
}

.technology-advice-image {
  width: 100%;
  height: auto;
}

.content {
  flex: 1;
  padding-left: 50px;
}

.content h2 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.content p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

/* Media query para pantallas pequeñas */
@media (max-width: 767px) {
  #technology-advice {
    flex-direction: column-reverse;
    padding: 20px;
  }

  .image-container {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .content {
    padding-left: 0;
    text-align: center;
  }

  .content h2 {
    font-size: 24px;
  }

  .content p {
    font-size: 16px;
  }
}
