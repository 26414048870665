#about-us {
  padding: 50px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef6ff;
  border-radius: 3rem;
  flex-wrap: wrap;
}

.about-us-content {
  max-width: 800px;
  margin-right: 20px;
  margin-left: 5%;
  flex: 1;
}

.about-us h2 {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.about-us p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

.about-us-image {
  flex: 1;
  text-align: center;
  max-width: 500px; /* Ajusta según el tamaño deseado */
}

.about-us-image img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  #about-us {
    flex-direction: column;
    text-align: center;
  }

  .about-us-content {
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .about-us h2 {
    font-size: 28px;
  }

  .about-us p {
    font-size: 16px;
  }

  .about-us-image {
    order: 1;
    margin-top: 20px;
  }
}
