/* --------------- Banner --------------- */
.banner {
  display: flex;
  flex-direction: row; /* Mantiene los elementos en fila en pantallas grandes */
  align-items: center;
  padding: 20px;
  background-color: #282c34;
  color: white;
  height: auto; /* Ajusta la altura automáticamente según el contenido */
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en pantallas pequeñas */
  width: 100%; /* Asegura que el banner ocupe el ancho completo del contenedor */
  box-sizing: border-box; /* Incluye el padding en el ancho total del contenedor */
}

.banner-text {
  flex: 1; /* Permite que el texto ocupe el espacio disponible */
  max-width: 50%;
  margin-right: 20px; /* Espacio entre el texto y la imagen */
  margin-left: 3rem; /* Espacio entre el texto y la imagen */
  text-align: center; /* Alinea el texto al centro */
  box-sizing: border-box; /* Incluye el padding en el ancho total del contenedor */
}

.banner-text h1 {
  font-size: 2.5rem;
  margin: 0;
}

.banner-text p {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #f1f1f1;
}

.banner-image {
  flex: 1; /* Permite que la imagen ocupe el espacio disponible */
  max-width: 40%;
  text-align: right; /* Alinea la imagen a la derecha */
  box-sizing: border-box; /* Incluye el padding en el ancho total del contenedor */
}

.banner-image img {
  width: 100%;
  height: auto;
  max-height: 100%; /* Ajusta la altura máxima de la imagen */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner {
    flex-direction: column; /* Coloca los elementos en una columna en pantallas pequeñas */
    padding: 10px;
  }

  .banner-text {
    max-width: 100%; /* Asegura que el texto ocupe todo el ancho disponible en pantallas pequeñas */
    margin-right: 0; /* Elimina el margen derecho en pantallas pequeñas */
    margin-bottom: 20px; /* Espacio inferior para separar el texto de la imagen */
    margin-left: 0; /* Elimina el margen izquierdo en pantallas pequeñas */
  }

  .banner-image {
    max-width: 100%; /* Asegura que la imagen ocupe todo el ancho disponible en pantallas pequeñas */
    text-align: center; /* Centra la imagen en pantallas pequeñas */
  }

  .banner-text h1 {
    font-size: 1.8rem; /* Tamaño reducido del título en móviles */
  }

  .banner-text p {
    font-size: 1rem; /* Tamaño reducido del párrafo en móviles */
  }
}

/* --------------- Brands --------------- */
.brands-section {
  width: 100%;
  padding: 20px;
  background-color: #f1f1f1;
  text-align: center;
  box-sizing: border-box; /* Incluye el padding en el ancho total del contenedor */
}

.brands-section h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #282c34;
}

.brands-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px; /* Ajusta el espacio entre los logos */
  margin: 0 auto; /* Centra el contenedor de logos */
  width: 100%;
  box-sizing: border-box; /* Incluye el padding en el ancho total del contenedor */
}

.brands-logos img {
  height: 100px;
  object-fit: contain;
  max-width: 100%; /* Asegura que la imagen no se desborde */
  box-sizing: border-box; /* Incluye el padding en el ancho total del contenedor */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .brands-section h2 {
    font-size: 1.5rem; /* Tamaño reducido del encabezado en móviles */
  }

  .brands-logos img {
    height: 80px; /* Tamaño reducido de los logos en móviles */
  }
}
