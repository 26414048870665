/* --------------- Info Section --------------- */
#info-section {
  text-align: center;
  padding: 40px;
  background-color: #f8f8f8;
}

#info-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #334973;
  margin-bottom: 20px;
}

.info-section-description {
  font-size: 1.125rem;
  line-height: 1.6;
  color: #334973;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

#info-section .info-section {
  max-width: 1200px;
  margin: 0 auto;
}

.develop-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.develop-section img {
  height: 100px;
  object-fit: contain;
  margin: 10px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 200px;
}

.image-container img {
  width: 100%;
  margin-top: 10px;
}

.image-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #334973;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 50px;
}

/* Clase para rotar flechas en pantallas pequeñas */
.flecha {
  display: block; /* Asegura que la rotación se aplique correctamente */
}

/* Rotar flechas en pantallas pequeñas */
@media (max-width: 767px) {
  .flecha {
    transform: rotate(90deg);
    margin: 20px; /* Ajusta el margen si es necesario */
  }

  #info-section {
    padding: 20px;
  }

  #info-section h1 {
    font-size: 1.8rem;
  }

  .info-section-description {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  .image-empty-description {
    display: none; /* Ocultar las descripciones de las imágenes */
  }

  .develop-section {
    flex-direction: column;
    align-items: center;
  }

  .develop-section img {
    height: 60px;
  }

  .image-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .image-description {
    font-size: 0.875rem;
    margin: 0 auto;
  }
}
